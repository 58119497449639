const projects = [
  {
    name: "Circuit Hacker",
    thumbnail: "thumbnails/circuit-hacker-thumbnail.png",
    thumbnailAltText: "Picture of an enlarge atom in front of a circuit board with the words 'Circuit Hacker' in front.",
    skillsUsed: [
        "C#",
        "Unity",
        "Pixel Art",
        "Music Composition",
        "Sound Design"
    ],
    description: "This is a shoot-em-up game that I made for the GMTK 2024 game jam. This project taught me a lot about how proper organization of files can save you a lot of time even in the short term. Since I had only 4 days to complete this project, I had to save time wherever I could.",
    projectLink: "https://mastersanjai.itch.io/circuit-hacker"
  },
  {
    name: "Sedensura (Demo)",
    thumbnail: "thumbnails/sedensura-thumbnail.png",
    thumbnailAltText: "Picture of Jack from Sedensura",
    skillsUsed: [
      "C#",
      "Unity",
      "Ableton Live",
      "Pixel Art",
      "Sound Design"
    ],
    description: "A simple 2D platformer I made in Unity. I learned a lot about useful design patterns, and the creative process in general.",
    projectLink: "https://mastersanjai.itch.io/sedensura"
  },
  {
    name: "Portfolio Page",
    thumbnail: "thumbnails/react-logo.png",
    thumbnailAltText: "Picture of William Wallace",
    skillsUsed: [
      "React",
      "Jest",
      "AWS",
      "GitLab CI/CD"
    ],
    description: "This is my portfolio page that you're looking at right now. I'm using this to showcase my work and skills. I also added a few tools to this webpage like a Base64 encoder/decoder. These are some basic tools that I find I use a lot when coding and I wanted to have a webpage to use these tools that didn't have a bunch of ads.",
    projectLink: ""
  },
  {
    name: "BMP Renderer",
    thumbnail: "thumbnails/bmp-renderer-sphere.bmp",
    thumbnailAltText: "Picture of a cube rendered using BMP renderer",
    skillsUsed: [
      "C++",
      "Linear Algebra"
    ],
    description: "A simple 3D rendering engine that takes polygons in a scene and renders them into a BMP image. This is a project for practicing my C++ and familiarize myself with how 3D rendering engines work.",
    projectLink: "https://gitlab.com/masterqwerty/bmp-renderer"
  }
]

export default projects;
