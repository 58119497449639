import "./Utils.scss";

import { Component } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Base64Encoder from "../../components/Base64Encoder/Base64Encoder";
import URLEncoder from "../../components/URLEncoder/URLEncoder";
import JsonPrettyPrint from "../../components/JsonPrettyPrint/JsonPrettyPrint";
import UuidGenerator from "../../components/UuidGenerator/UuidGenerator";
import Select from "react-select";

class Utils extends Component {
  constructor() {
    super();

    this.state = {
      currentTool: ""
    }

    this.changeTool = this.changeTool.bind(this);
  }

  changeTool(e) {
    this.setState({ currentTool: e.value });
  }

  render() {
    let currentTool;
    switch (this.state.currentTool) {
      case "base64":
        currentTool = <Base64Encoder />
        break;
      case "urlencode":
        currentTool = <URLEncoder />
        break;
      case "jsonpp":
        currentTool = <JsonPrettyPrint />
        break;
      case "uuidgen":
        currentTool = <UuidGenerator />
        break;
      default:
        currentTool = <div></div>
        break;
    }

    const toolOptions = [
      { value: "base64", label: "Base64 Encode/Decode" },
      { value: "urlencode", label: "URL Encode/Decode" },
      { value: "jsonpp", label: "JSON Pretty Print" },
      { value: "uuidgen", label: "UUID Generator" }
    ]

    const selectBackgroundColor = "#181c24";
    const hoverBackgroundColor = "#6f7680";

    return (
      <div className="Utils" data-testid="Utils">
        <NavBar active="utils" />
        <div className="Utils-main">
          <div className="Utils-tool-select-container">
            <Select classNamePrefix="Utils-tool-select" onChange={this.changeTool} aria-label="Tool Select" options={toolOptions}
              value={this.state.value}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  backgroundColor: selectBackgroundColor,
                  marginLeft: "10px",
                  width: "300px",
                  color: "#ffffff"
                }),
                singleValue: baseStyles => ({
                  ...baseStyles,
                  color: "#ffffff"
                }),
                option: baseStyles => ({
                  ...baseStyles,
                  backgroundColor: selectBackgroundColor,
                  ":hover": {
                    backgroundColor: hoverBackgroundColor
                  }
                }),
                menuList: baseStyles => ({
                  ...baseStyles,
                  backgroundColor: selectBackgroundColor
                }),
              }}/>
          </div>
          <div className="Utils-tool-container">
            {currentTool}
          </div>
        </div>
      </div>
    )
  }
}

export default Utils;
